import ContactForm from "./ContactForm";

function ContactUs() {
  return (
    <>
    <div className="bg-uniqspaces max-w-screen mt-4 md:mt-24  px-4 md:px-12 lg:px-16 xl:px-12 py-16 lg:mx-24 bg-gray-100 text-gray-900 shadow-lg">
      <div className="grid gap-8 grid-cols-1 md:grid-cols-2 mb-8">
        <div className="flex flex-col justify-between">
          <div>
            <h2 className="text-4xl lg:text-5xl font-bold leading-tight">
              Lets talk about everything!
            </h2>
            <div className="text-gray-700 mt-4">
              Hate forms? Send us an email at{" "}
              <a href="mailto:mailus@uniqspaces.co.in" className=" underline">
                mailus@uniqspaces.co.in
              </a>{" "}
              instead.
            </div>
          </div>
          <div className="mt-2">
            <h3 className="text-xl lg:text-2xl font-bold leading-tight">
              Call us
            </h3>
            <p> +91-7019121166 </p>
          </div>
          <div className="mt-2">
            <h3 className="text-xl lg:text-2xl font-bold leading-tight">
              Reach us at
            </h3>
            <p> UniQ spaces </p>
            <p> #11, 3rd floor, 11th cross, </p>
            <p> Chikkamaranahalli, New Bel Road, </p>
            <p> Bengaluru - 560094</p>
          </div>
        </div>
        <div className="mt-4">
          <iframe 
            title="UniQ Spaces Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.940313394709!2d77.56399119999999!3d13.0394712!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae17e2266b07cb%3A0x7ec9c75ab5646141!2sUniQ%20Spaces!5e0!3m2!1sen!2sin!4v1736667168638!5m2!1sen!2sin"
            style={{ width: '100%', height: '400px', border: 0 }} 
            allowfullscreen="" 
            loading="lazy" 
            referrerpolicy="no-referrer-when-downgrade">
          </iframe>
        </div>
      </div>
        <ContactForm />
      </div>
    </>
  );
}
export default ContactUs;
